jQuery(document).ready(function () {
    var viewport = document.querySelector("meta[name=viewport]");

    if(screen.width>1024)
    $('body').css('zoom', 100/window.devicePixelRatio+'%')

    // $(window).on('resize', function(){
    //     var win = $(this); //this = window
    //     if (win.width() > 767) {
    //         viewport.setAttribute("content", "width=device-width, user-scalable=yes, initial-scale=1.0");
    //     }
    //     if (win.width() < 767) {
    //
    //         viewport.setAttribute("content", "width=640, maximum-scale=1.0, user-scalable=no");
    //     }
    //     if(screen.width>1024)
    //     $('body').css('zoom', 100/window.devicePixelRatio+'%')
    // });
    // if (screen.width < 767) {
    //
    //     var audiobookLink = $('.js-audiobook-link'),
    //         popupLink = $('.js-proxy-loyal-popup a');
    //
    //     viewport.setAttribute("content", "width=640, maximum-scale=1.0, user-scalable=no");
    //
    //     if(audiobookLink.attr('data-url')) {
    //         audiobookLink.attr('data-url', '#').removeAttr('target');
    //     }
    //     else if(audiobookLink.attr('href')!='#') {
    //         audiobookLink.attr('href', '').removeAttr('target').addClass('playerPopup');
    //     }
    //     if(popupLink.attr('href')!='#'){
    //         popupLink.attr('href', '').removeAttr('target').addClass('playerPopup');
    //     }
    // }

    // $('.slider_2,.slider_1').owlCarousel({
    //     loop: true,
    //     dots: false,
    //     autoWidth: true,
    //     onInitialized: fixOwl,
    //     onRefreshed: fixOwl,
    //     responsive: {
    //         0: {
    //             nav: false,
    //             navText: ["", ""]
    //         },
    //         1280: {
    //             nav: true,
    //             navText: ["", ""]
    //         }
    //     }
    //
    // });

    // $('.home_banner').owlCarousel({
    //     items: 1,
    //     animateOut: 'fadeOut',
    //     loop: true,
    //     autoHeight:true,
    //     responsive: {
    //         0: {
    //             dots: true,
    //             autoplay: true,
    //             autoplayTimeout: 5000
    //         },
    //         1200: {
    //             dots: true,
    //             autoplay: true,
    //             autoplayTimeout: 5000
    //         }
    //     }
    // });

    $('body').on('click touch','.js-register-submit', function () {
        $(this).attr('disabled', 'disabled');
        $(this).closest('form').submit();
    });

    $('body').on('click touch','.js-clear_search', function () {
        $('.js-search-input').val('');
    });

    var isMain = true;

    $('.home_menu li a').each(function (index,element) {
        if($(this).hasClass('active')){
            $('.home_banner').trigger('to.owl.carousel', [index, 0]);
            isMain = false;
        }
    });

    // if(isMain){
    //     $('.home_banner').trigger('to.owl.carousel', [3, 0]);
    // }

    $('a.btn_lang').click(function () {
        $(this).find('i').toggleClass('transform_i');
    });

    $('body').click(function (el) {
        var target = $(el.target);
        if (!target.parents('.header_btnnav_nav').length && !target.parents('.header_menu').length) {
            $('.header_menu.in').removeClass('in');
        }
    });

    $('.header_btnnav_nav, .header_btn_nav').click(function (e) {
        e.stopImmediatePropagation();
        $('.header_menu').toggleClass('in');
    });

    $("<div/>").attr('class', 'bg_shadow').appendTo('.read-setting');

    $('.ac-collapse').click(function (e) {

        $(this).toggleClass('transform_b');
        var el = $('.ac_block_filter span');
        el.toggleClass('change_words');
        if (el.hasClass('change_words')) {
            el.text('Выбор сезона');
        } else {
            el.text('Все сезоны');
        }

    });
    $('.nav_books_reader button').click(function () {
        $(this).toggleClass('active');
    });


    var sheet = document.createElement('style'),
            $rangeInput = $('.range'),
            prefs = ['webkit-slider-runnable-track', 'moz-range-track', 'ms-track'];

    document.body.appendChild(sheet);

    var getTrackStyle = function (el) {
        var curVal = el.value,
                style = '';

        for (var i = 0; i < prefs.length; i++) {
            style += '.range::-' + prefs[i] + '{background: linear-gradient(to right, #e60000 0%, #e60000 ' + curVal + '%, #666 ' + curVal + '%, #666 100%)}';
        }
        return style;
    }

    $rangeInput.on('input', function () {
        sheet.textContent = getTrackStyle(this);
    });
    $rangeInput.on('input', function () {
        var curId = $(this).parent().attr('id');

        if (curId == 'range-distance') {
            sheet1.textContent = getTrackStyle(this, curId);
        } else if (curId == 'range-price') {
            sheet2.textContent = getTrackStyle(this, curId);
        }
    });

});
var fixOwl = function(el){
    var stage = $(el.target).children().children().first(),
        el = stage.children(),
        elW=0;
    el.each(function() {
      if($(this).hasClass('cloned')) {
          elW -= $(this).width();
      }
      else
      return false;
    });
    var tr = 'translate3d('+Math.round(elW)+'px, 0px, 0px)';
    setTimeout(function () {
        stage.css('transform',tr);
    },200)
}