;(function ($, document, window) {
    $(document).ready(function () {
        var thresholdPX = 400,
            body = $('body'),
            loadingInProgress = false,
            endlessLinks = $('.js-endless-link');

        $(window).scroll(function () {
            var bottom = $(this).scrollTop() + $(this).height();

            endlessLinks.each(function () {
                if ($(this).offset().top - bottom <= thresholdPX) {
                    // $(this).click();
                }
            });
        });

        body.on('click', '.js-endless-link', function () {
            var prevContainer = $('.js-endless-container'),
                url = $(this).attr('href');
            $(this).addClass('_loading');

            loadItems(url, prevContainer);

            return false;
        });

        function loadItems(url, prevContainer) {
            if (loadingInProgress) {
                return false;
            }

            loadingInProgress = true;

            $.ajax({
                method: 'GET',
                url: url
            }).done(function (html) {
                html = $(html);
                html = html.children();
                prevContainer.find('.js-endless-link-container').remove();
                prevContainer.append(html);
                var href = prevContainer.find('.js-endless-link-container').attr('href');
                if (href) {
                    $('.js-endless-link')
                        .attr('href', prevContainer.find('.js-endless-link-container').attr('href'));
                } else {
                    $('.js-endless-link').remove();
                }
            }).fail(function () {
                prevContainer.next().find('.js-endless-link')
                    .removeClass('_loading');
            }).always(function () {
                loadingInProgress = false;
                endlessLinks = $('.js-endless-link');
            });
        }
    });
})(jQuery, document, window);
